import produce from 'immer';
import create from 'zustand';
import {
  getOrders,
  setSapNumber,
  setCancelOrder,
  editOrder,
  uploadCEC,
  deleteCEC,
} from '../services/ordersService';
import { OrdersDataSource } from '../datasources/OrdersDataSource';
import { formatDate } from '../utils/utils';
import { PatientDataSource } from '../datasources/PatientDataSource';
import { CecDataSource } from '../datasources/CecDataSource';

export const useOrderStore = create((set, get) => ({
  rows: 0,
  page: 0,
  pageSize: 10,
  orders: [],
  setPagination: (page, pageSize) => {
    set({ page, pageSize });
  },
  fetchOrders: async (cnpj, filter) => {
    const params = {
      page: parseInt(get().page),
      page_size: parseInt(get().pageSize),
      number: filter?.find((x) => x.name === 'Nº Pedido')?.value,
      type: filter?.find((x) => x.name === 'Tipo')?.value,
      status: filter?.find((x) => x.name === 'Status')?.value,
      cpf: filter?.find((x) => x.name === 'CPF')?.value,
      cnpj: filter?.find((x) => x.name === 'CNPJ')?.value,
      patient_name: filter?.find((x) => x.name === 'Paciente')?.value,
      initial_date: formatDate(
        filter?.find((x) => x.name === 'Data')?.value[0]
      ),
      final_date: formatDate(filter?.find((x) => x.name === 'Data')?.value[1]),
      order_sap_number: filter?.find((x) => x.name === 'Nº Pedido SAP')?.value,
      client_sap_number: filter?.find((x) => x.name === 'Nº Cliente SAP')
        ?.value,

      initial_delivery_date: formatDate(
        filter?.find((x) => x.name === 'Estimativa de entrega')?.value[0]
      ),
      final_delivery_date: formatDate(
        filter?.find((x) => x.name === 'Estimativa de entrega')?.value[1]
      ),
      client: filter?.find((x) => x.name === 'Empresa')?.value,
      alarm: filter?.find((x) => x.name === 'Sinal luminoso')?.value,
      operator: filter?.find((x) => x.name === 'Operador Responsável')?.value,
      distribution_center: filter?.find((x) => x.name === 'Filial Distribuidora')?.value,
      _timestamp: new Date().getTime(),
    };

    const value = await new OrdersDataSource().getAll(params);

    set(
      produce((state) => {
        state.orders = [...value.data];
        state.rows = value.total;
      })
    );
  },
  uploadCEC: async (CECs) => {
    await uploadCEC(CECs);
  },
  setSapNumber: async (cnpj, orderNumber, sapNumber) => {
    await setSapNumber(cnpj, orderNumber, sapNumber);
  },
  setCancelOrder: async (cnpj, orderNumber, orderStatus) => {
    await setCancelOrder(cnpj, orderNumber, orderStatus);
  },
  editOrder: async (cnpj, orderToUpdate) => {
    await editOrder(cnpj, orderToUpdate);
  },
  deleteCEC: async (sap_number) => {
    await deleteCEC(sap_number)
  }
}));

function updateAlarmById(order_, id, newAlarm) {
  return {
    ...order_,
    data: order_.data.map((item) =>
      item.id === id ? { ...item, alarm: newAlarm } : item
    ),
  };
}