import { formatDate, formatDateToISO, unformatCPF } from '../utils/utils';
import { doGet, doPost, doDelete } from './httpHelper';
import { getBranch } from './patientsService';
import { getProductsByCodes } from './productService';
import moment from 'moment';
import { UserHelper } from '../helpers/UserHelper';
import { OrderEnums } from '../enums/OrderEnums';
import { CecDataSource } from '../datasources/CecDataSource';
import { Buffer } from 'buffer';

const CAT_ACESSORIOS = 4;

export const getOrders = async (cnpj, filter, page, pageSize) => {
  const params = {
    clientCode: null,
    searchText: null,
    patientCode: null,
    initialDate: null,
    finalDate: null,
    page: page,
    pageSize: pageSize,
    cnpj: null,
    status: null,
    numPedido: null,
    requestType: null,
    cpf: null,
  };
  if (filter) {
    filter.forEach((filter) => {
      if (filter.name === 'CPF') {
        params.cpf = filter.value;
      } else if (filter.name === 'Tipo') {
        params.requestType = filter.value;
      } else if (filter.name === 'Nº Pedido') {
        params.numPedido = filter.value;
      } else if (filter.name === 'Paciente') {
        params.searchText = filter.value;
      } else if (filter.name === 'Status') {
        params.status = filter.value;
      } else if (filter.name === 'CNPJ') {
        params.cnpj = filter.value;
      } else if (filter.name === 'Data') {
        params.initialDate = formatDate(filter.value[0]);
        params.finalDate = formatDate(filter.value[1]);
      }
    });
  }
  const orders = await getRequests(...Object.values(params));
  const productCode = orders.data
    .map((x) => x.products)
    .flat()
    .filter((x) => x.product_category_id > 0)
    .map((x) => x.code)
    .filter((value, index, self) => self.indexOf(value) === index);

  const products = await getProductsByCodes(productCode);
  const mapProd = {};
  products.forEach((p) => {
    mapProd[p.sap_code] = p;
  });

  orders.data = orders.data.map((order) => ({
    id: order.id,
    orderNumber: order.number ?? order.request_number_temp,
    sapOrderNumber: order.request_number ?? '',
    request_number_temp: order.request_number_temp,
    type: order.request_type,
    name: order.nome,
    clientSapNumber: order.sap_client,
    cpf: order.cpf,
    cnpj: order.cnpj,
    clientName: order.client,
    createdAt: order.create_in,
    orderReceiptUrl: [],
    status: order.request_status,
    status_complement: order.status_complement,
    distribution_center: order.distribution_center,
    responsible_operator: order.responsible_operator,
    delivery_window: order.delivery_window,
    estimateDelivery: formatDateToISO(order.delivery_forecast),
    obs: order.obs,
    createdBy: order.create_by,
    alarm: order.alarm,
    patient: {
      name: order.nome,
      cep: order.address?.zipcode ?? '',
      city: order.address?.city ?? '',
      address: order.address?.address ?? '',
      uf: order.address?.UF ?? '',
      addressLine2: '',
      addressNumber: order.address.number,
    },
    service: {
      type: order.category,
      equipments: order.products
        .filter((p) => p.product_category_id !== CAT_ACESSORIOS)
        .map((p) => {
          const prod = { ...p };
          const fullProd = mapProd[p.code];
          prod.name =
            fullProd && fullProd.description
              ? fullProd.description
              : prod.product;
          prod.category = fullProd ? fullProd.product_category.description : '';
          prod.subCategory = fullProd
            ? fullProd.product_subcategory.description
            : '';
          return prod;
        }),
      accessories: order.products
        .filter((p) => p.product_category_id === CAT_ACESSORIOS)
        .map((p) => {
          const prod = { ...p };
          const fullProd = mapProd[p.code];
          prod.name =
            fullProd && fullProd.description
              ? fullProd.description
              : prod.product;
          prod.category = fullProd ? fullProd.product_category.description : '';
          prod.subCategory = fullProd
            ? fullProd.product_subcategory.description
            : '';
          prod.quantity = p.quantity_volume ?? 0;
          return prod;
        }),
      endDate: new Date().toDateString(),
    },
  }));

  orders.data = orders.data.sort((x) => x.createdAt).reverse();
  return orders;
};

export const setSapNumber = async (cnpj, orderNumber, sapNumber) => {
  const data = { request_number_temp: orderNumber, sap_number: sapNumber };
  await doPost('/setSapNumbetToRequest', data);
};
export const setCancelOrder = async (cnpj, orderNumber, orderStatus) => {
  const data = {
    request_number_temp: orderNumber,
    request_status: orderStatus,
  };
  await doPost('/setCancelToRequest', data);
};
export const editOrder = async (cnpj, orderToUpdate) => {
  await doPost('/editOrder', orderToUpdate);
};

export const uploadCEC = async (CECs) => {
  await doPost('/uploadCEC', {
    ...CECs,
    creation_date: moment().utc(true).toISOString(),
  });
};

export const deleteCEC = async (sap_number) => {
  await new CecDataSource().delete(sap_number);
};

const defaultWindow = [
  {
    Day: 'Seg',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'S',
    'D+0': 'S',
  },
  {
    Day: 'Ter',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'S',
    'D+0': 'S',
  },
  {
    Day: 'Qua',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'S',
    'D+0': 'S',
  },
  {
    Day: 'Qui',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'S',
    'D+0': 'S',
  },
  {
    Day: 'Sex',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'S',
    'D+0': 'S',
  },
  {
    Day: 'Sab',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'N',
    'D+0': 'S',
  },
  {
    Day: 'Dom',
    From: '08:00:00',
    Until: '17:00:00',
    Active: 'N',
    'D+0': 'S',
  },
];
export const getWindowAttendance = async (cityName) => {
  const city = await getCityByName(cityName);
  if (!city) {
    return {
      Unit: '23353213',
      City: 'MOCK',
      D0: 'N',
      windows: defaultWindow,
    };
  }
  const resp = await doGet(`/windowAttendance?cityId=${city.id}`);
  const wnd = resp.data[0];
  wnd.windows.forEach((window) => {
    if (!window.Active) {
      window.Active = 'S';
    }
  });
  // if (wnd && Array.isArray(wnd.windows) && wnd.windows.length === 0) {
  //     wnd.windows = [...defaultWindow]
  // }
  return wnd;
};

export const getCityByName = async (city) => {
  const resp = await doGet(`/cityByName?name=${city}`);
  return resp.data[0];
};

export const getProofOfDelivery = async (orderId, isArray = false) => {
  if (!isArray) {
    if (orderId.length !== 8) return ;
    const resp = await new CecDataSource().getByRequestNumber(orderId);
    return resp.map((img) => Buffer.from(img.picture.data).toString('base64'));
  } else {
    const results = [];
    for (const sapNumber of orderId) {
      if (sapNumber.length !== 8) {
        continue;
      }
      const resp = await new CecDataSource().getByRequestNumber(sapNumber);
      const result = resp.map((img) =>
        Buffer.from(img.picture.data).toString('base64')
      );
      if (result.length !== 0) {
        results.push(result[0]);
      }
    }
    return results;
  }
};

export const getProofOfDeliveryObj = async (
  orderIds,
  isArray = false,
  getObj = false
) => {
  if (!isArray) {
    if (orderIds.length !== 8) return [];
    const resp = await new CecDataSource().getByRequestNumber(orderIds);
    const result = resp.map((img) =>
      Buffer.from(img.picture.data).toString('base64')
    );
    if (getObj) {
      return { [orderIds]: result };
    }
    return result;
  } else {
    const results = {};
    for (const sapNumber of orderIds) {
      if (sapNumber.length !== 8) {
        continue;
      }
      const resp = await new CecDataSource().getByRequestNumber(sapNumber);
      const result = resp.map((img) =>
        Buffer.from(img.picture.data).toString('base64')
      );
      if (result.length !== 0) {
        results[sapNumber] = result[0];
      }
    }
    return results;
  }
};

export const saveOrder = async (
  currentUser,
  cnpj,
  patient,
  category,
  subCategory,
  accessories,
  deliveryDate,
  deliveryTurn,
  obs
) => {
  const branch = await getBranch(patient.city);
  const turns = [];
  Object.keys(deliveryTurn).forEach((turn) => {
    if (deliveryTurn[turn]) {
      turns.push(turn);
    }
  });
  let prods = [];
  prods = Object.values(accessories)
    .filter((x) => x.value !== 'Não')
    .map((x) => x.productsToAdd)
    .flat()
    .map((code) => ({
      product_code: code.toString(),
      product_unit_value: 0,
      quantity_volume: 0,
    }));
  prods.push({
    product_code: (subCategory.sap_code ?? subCategory.id).toString(),
    product_unit_value: 0,
    quantity_volume: 0,
  });
  const mapProd = {};
  prods
    .filter((p) => parseInt(p.product_code, 10) !== 99999999)
    .forEach((p) => {
      if (!mapProd[p.product_code]) {
        mapProd[p.product_code] = p;
      }
      mapProd[p.product_code].quantity_volume++;
    });
  const obj = {
    request_number_temp: new Date().getTime().toString(),
    commercialization_type: 'P',
    request_type: new OrderEnums().enums.types.IMPLANTATION,
    status_id: new OrderEnums().enums.status.UNDER_ANALYSIS,
    sap_client: patient.sap_number ?? '',
    //"client": "Nome do Cliente",
    cnpj: cnpj,
    cpf: patient.cpf,
    distribution_center: '',
    destination_sys_cod: '0',
    destination_system: 'SAP',
    date_entry: formatDate(new Date()),
    serial_note: '',
    products: Object.values(mapProd),
    net_total_value: 0,
    total_with_tax: 0,
    sta_date: formatDate(new Date()),
    create_in: moment().utc(true).toISOString(),
    create_by: currentUser.email,
    order_type_desc: new OrderEnums().enums.types.IMPLANTATION,
    delivery_forecast: formatDate(deliveryDate),
    delivery_window: turns.join('/'),
    num_carga: '0',
    obs: obs ?? '',
  };

  // Setting responsible operator
  const currentUserJSON = UserHelper.getCurrentUser();
  if (currentUserJSON) {
    if (
      currentUserJSON.isWhiteMartins === '1' ||
      currentUserJSON.isAdmin === '1'
    )
      obj.operator_cognito_id = currentUserJSON.username;
  }

  const resp = await doPost('/requests', obj);

  if (resp.message !== 'Request created') {
    return 'erro interno';
  }

  return obj.request_number_temp;
};

export const getRequests = async (
  clientCode,
  searchText,
  patientCode,
  initialDate,
  finalDate,
  page,
  pageSize,
  cnpj,
  status,
  numPedido,
  requestType,
  cpf
) => {
  let url = `/requests?`;
  const params = [];
  if (clientCode && clientCode !== '') {
    params.push('clientCode=' + clientCode);
  }
  if (searchText && searchText !== '') {
    params.push('searchText=' + searchText);
  }
  if (patientCode && patientCode !== '') {
    params.push('patientCode=' + patientCode);
  }

  if (cnpj && cnpj !== '') {
    params.push('cnpj=' + cnpj);
  }

  if (cpf && cpf !== '') {
    params.push('cpf=' + unformatCPF(cpf));
  }

  if (status && status !== '') {
    params.push('status=' + status);
  }

  if (numPedido && numPedido !== '') {
    params.push('numPedido=' + numPedido);
  }

  if (requestType && requestType !== '') {
    params.push('requestType=' + requestType);
  }

  if (initialDate && initialDate !== '') {
    params.push('startDate=' + initialDate);
  }
  if (finalDate && finalDate !== '') {
    params.push('endDate=' + finalDate);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  return resp;
};
