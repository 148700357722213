import axios, { AxiosInstance } from 'axios';
import { JWTHelper } from '../helpers/JWTHelper';

const API_URL = process.env.REACT_APP_WM_API_URL as string;
const API_KEY = process.env.REACT_APP_WM_API_KEY as string;

export class Api {
  private token = JWTHelper.getTokenFromStorage() as string;
  private api: AxiosInstance;

  constructor(baseURL = API_URL) {
    this.api = axios.create({ baseURL: baseURL });
  }

  async fetch(route: string): Promise<unknown> {
    const response = await this.api.get(route, {
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${this.token}`,
      },
    });

    return response.data;
  }

  async post<T>(route: string, data: T): Promise<unknown> {
    const response = await this.api.post(route, data, {
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${this.token}`,
      },
    });

    return response.data;
  }

  async put<T>(route: string, data: T): Promise<unknown> {
    const response = await this.api.put(route, data, {
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${this.token}`,
      },
    });

    return response.data;
  }

  async delete(route: string): Promise<unknown> {
    const response = await this.api.delete(route, {
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${this.token}`,
      },
    });

    return response.data;
  }
}