import { Api } from '../api';
import { Cec, RequestCEC } from '../types/CEC';

export class CecDataSource {
  async getByRequestNumber(requestNumber: string) {
    try {
      const result = (await new Api().fetch(
        `/request/picture/${requestNumber}`
      )) as Cec;
      return result;
    } catch (e: any) {
      return [];
    }
  }

  async create(data: RequestCEC) {
    try {
      const result = await new Api().post('/cecs/', data);
      return result;
    } catch (e: any) {
      return [];
    }
  }

  async delete(requestNumber: string) {
    try {
      const result = await new Api().delete(`/cecs/${requestNumber}`);
      return result;
    } catch (e: any) {
      return [];
    }
  }
}